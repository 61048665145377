import React, {} from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';

import emptyLightGreyImg from '^/userWeb/Assets/lightgrey.jpg';

import { useMounted } from '../hooks/useMounted';
// import { borders } from '../styles/commonClasses';
import { borders } from '../styles/commonClasses';
import {
  BackgroundColors, FontSizes, FontWeights, TextColors,
} from '../styles/styleConstants';

import { VerticalDivider } from './Dividers/VerticalDivider';
import { MediaPreview } from './MediaPreview';

export const NotificationVideoCell: React.FC<{
  date: string;
  title: string;
  rootCSS?: string;
  src: string | null;
  onClick?(): void;
  onDoubleClick?(): void;
  // isFullScreen?: MobxValue<boolean>;
}> = observer(function NotificationVideoCell(props) {
  const {
    ref: inViewRef,
    inView,
  } = useInView({
    rootMargin: '20px',
  });
  useMounted(
    `NotificationVideoCell.tsx ${props.date}`,
  );
  return (
    <div
      ref={inViewRef}
      className={[
        css`
          display: flex;
          flex-direction: column;

          &:active {
            opacity: 0.5;
          }
        `,
        // borders.DEBUG_blueBorderThickDashed,
      ].join(' ')}
    >
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: row;
            padding-top: 16px;
            padding-bottom: 16px;
            gap: 12px;
          `,
          props.rootCSS,
        )}
      >
        <div
          className={css`
                      display: flex;
                      flex-grow: 0.7;
                      flex-direction: column;
                      gap: 8px;
                    `}
        >
          <p
            className={css`
                          color: ${TextColors.black_light};
                          font-size: ${FontSizes.base};
                          font-weight: ${FontWeights.regular};
                          text-align: left;
                          margin: 0px;
                        `}
          >{props.date}
          </p>
          <p
            className={css`
                          color: ${TextColors.black};
                          font-size: ${FontSizes.lg};
                          font-weight: ${FontWeights.bold};
                          text-align: left;
                          margin: 0px;
                        `}
          >{props.title}
          </p>
        </div>
        {inView ? (
          <MediaPreview
            // onDoubleClick={props.}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            mediaCSS={[
              css`
                flex-grow: 1;
                align-self: flex-start;
                background-color: ${BackgroundColors.gray_light};
                border-radius: 4px;
                min-width: 128px;
                height: auto;
                aspect-ratio: 16 / 10;
              `,
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
            mediaSrc={props.src || emptyLightGreyImg}
          />
        ) : (
          <MediaPreview
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            mediaCSS={[
              css`
                flex-grow: 1;
                align-self: flex-start;
                // background-color: purple;
                background-color: ${BackgroundColors.gray_light};
                border-radius: 4px;
                min-width: 128px;
                width: 100%;
                height: auto;
                aspect-ratio: 16 / 10;
              `,
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
          // mediaSrc={emptyLightGreyImg}
          />
        )}
      </div>
      <div
        className={cx(
          VerticalDivider,
          css`
            width: 100%;
          `,
        )}
      />
    </div>
  );
});
