/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { BaseChip } from '^/userWeb/Components/Chips/BaseChip';
import { WarningChip } from '^/userWeb/Components/Chips/WarningChip';
import { hideScrollBar } from '^/userWeb/styles/commonClasses';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import type { LiveCamsSliderAreaViewModel } from './LiveCamsSliderArea.viewmodel';

export const LiveCamsRadioChips: React.FC<{
  liveCamsResult: ExtractPromiseSuccess<LiveCamsSliderAreaViewModel['liveCamsStatuses']>;
  slideIndex: MobxValue<number>;
}> = observer(function LiveCamsRadioChips({
  liveCamsResult,
  slideIndex,
}) {
  console.log('aa', slideIndex.value);
  const scrollElemRef = useRef<HTMLDivElement>(null);
  const currentElemRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!currentElemRef.current || !scrollElemRef.current) {
      return;
    }
    // debugger;
    scrollElemRef.current.scrollTo({
      left: currentElemRef.current.offsetLeft - (window.innerWidth / 3),
      behavior: 'smooth',
    });
    // currentElemRef.current.scrollIntoView();
    console.log('slideIndex changed:', slideIndex.value);
  }, [slideIndex.value, currentElemRef, scrollElemRef]);
  return (
    <div
      id="LiveCamsRadioChips_ROOT"
      ref={scrollElemRef}
      className={cx(
        css`
          margin-top: 20px;
          overflow-y: scroll;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          gap: 20px;
        `,
        hideScrollBar,
      )}
    >
      {liveCamsResult.map((
        cam,
        nth,
      ) => {
        const isSelected = slideIndex.value === nth;
        if (cam.status === 'failure') {
          return (
            <WarningChip
              ref={isSelected ? currentElemRef : null}
              title="카메라정보 없음"
              key={`${nth}_camStatus_failure`}
              isSelected={isSelected}
              onClick={() => slideIndex.setValue(nth)}
            />
          );
        }

        if (cam.status === 'loading') {
          return (
            <BaseChip
              ref={isSelected ? currentElemRef : null}
              title="로딩중"
              key={`${nth}_camStatus_loading`}
              isSelected={isSelected}
              onClick={() => slideIndex.setValue(nth)}
            />
          );
        }
        const subscription = cam.value.subscription.current();
        if (!subscription || subscription.status === 'loading') {
          return (
            <BaseChip
              ref={isSelected ? currentElemRef : null}
              title={`로딩중: ${cam.value.name}`}
              key={`${cam.value.camId}__loading`}
              isSelected={isSelected}
              onClick={() => slideIndex.setValue(nth)}
            />
          );
        }
        if (subscription.status === 'failure') {
          console.error(
            'LiveCamSubscriptionFail: ',
            cam.value.name,
            cam.value.camId,
            subscription.failure,
          );  
          const errorLog = subscription.failure.reason === 'execFileException'
            ? (subscription.failure.logfileContent || 'execFileException_NO_LOGFILE') : subscription.failure.reason;
          const splitLogs = errorLog.split('\n');
          const relevantLog = splitLogs.slice(
            splitLogs.length - 10,
          )
            .concat([`time: ${subscription.time}`])
            .join('\n');
          return (
            <WarningChip
              ref={isSelected ? currentElemRef : null}
              title={cam.value.name}
              key={`${cam.value.camId}__fail`}
              isSelected={isSelected}
              onClick={(e) => {
                slideIndex.setValue(nth);
                if (e.detail === 2) {
                  alert(relevantLog);
                  // alert(errorLog);
                  if (e.shiftKey) {
                    alert(errorLog);
                  }
                }
                
              }}
            />
          );
        }
        const detail = subscription.value;
        const time = subscription.time;
        return (
          <BaseChip
            ref={isSelected ? currentElemRef : null}
            key={cam.value.camId}
            isSelected={isSelected}
            title={cam.value.name}
            onClick={(e) => {
              slideIndex.setValue(nth);
              if (e.detail === 2) {
                alert(`time: ${time}__${JSON.stringify(detail, null, 2)}`);
              }
            }}
          />
        );
      })}
    </div>
  );
});
