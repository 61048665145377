/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/** @jsx jsx */
import React, { useCallback, useRef } from 'react';

import { css } from '@emotion/css';
import { m } from 'framer-motion';

import { useDoubleTap } from '../hooks/useDoubleTap';
// import { useLongPress } from '../hooks/useLongPress';
import { borders } from '../styles/commonClasses';
import { browserInfo } from '../utils/browserInfo';
import { isImageExt } from '../utils/isImageExt';

export const MediaPreview: React.FC<{
  mediaSrc?: string;
  mediaCSS?: string;
  onDoubleClick?(): void;
  onClick?(): void;
  alt?: string;
}> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playOnClick = useCallback(() => {
    if (!videoRef.current) {
      return;
    }
    const isPlaying = videoRef.current.currentTime > 0
      && !videoRef.current.paused
      && !videoRef.current.ended
      && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
    // https://stackoverflow.com/a/36898221/19531789
    if (videoRef.current.paused && !isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [videoRef]);
  const onDoubleTap = useDoubleTap(
    () => props.onDoubleClick && props.onDoubleClick(),
    600,
    {
      onSingleTap: playOnClick,
    },
  );

  const showImgTag = (props.mediaSrc && isImageExt(props.mediaSrc))
    || browserInfo?.name === 'safari'
    || browserInfo?.os === 'iOS';

  if (showImgTag) {
    /* {...onDoubleTap} */
    return (
      <m.img
        alt={props.alt}
        src={props.mediaSrc}
        className={[
          props.mediaCSS,
          css`
          `,
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      />
    );
  }
  return (
    <m.video
      layoutId={props.mediaSrc}
      autoPlay
      muted
      playsInline
      ref={videoRef}
      className={props.mediaCSS}
      src={props.mediaSrc}
      // onClick={playOnClick}
      // onDoubleClick={props.onDoubleClick}
      {...onDoubleTap}
    />
  );
};
