import axios from 'axios';

import { stripMargin } from '^/utils/stripMargin';

const appVersion = (window as any).APP_VERSION;
export async function checkVersionAndRefresh() {
  const lastUpdate = Number(localStorage.getItem('versionRefreshTimestamp') || '0');
  try {
    const req = await axios.get('/APP_VERSION.txt', {
    });
    const versionInServer = (req.data as string).trim();
    if (versionInServer !== appVersion) {
      const currentTimestamp = (new Date()).getTime();
      if ((currentTimestamp - lastUpdate) < 60000 * 5) {
        // skip update if last update is lesser than 5 min (prevent self-ddos)
        return;
      }
      // eslint-disable-next-line no-restricted-globals, no-alert
      const confirmUpdate = confirm(
        stripMargin`업데이트가 있습니다!
                  |새 앱으로 업데이트 하시겠습니까?`,
      );
      if (!confirmUpdate) return;
      localStorage.setItem('versionRefreshTimestamp', String(currentTimestamp));
      await Promise.all(
        (await caches.keys()).map((k) => {
          return caches.delete(k);
        }),
      );
      // TODO: maybe later? (might break push notification)
      // const serviceWorkers = await window.navigator.serviceWorker
      //   .getRegistrations();
      // serviceWorkers.map((r) => r.unregister());
      window.location.reload();
    }
  } catch (e) {
    console.error('checkVersionAndRefresh failed: ', e);
  }
}

export function runCheckVersionAndRefreshInterval(
  intervalMs = 3600000 * 1, // 1-hour interval
) {
  checkVersionAndRefresh();
  setInterval(checkVersionAndRefresh, intervalMs);
}
